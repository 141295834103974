.writing {
  padding: 1.5rem;
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 1130px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

h1 {
  margin: 2rem 0;
}

h4 {
  font-weight: 500;
  color: #a1a1a1;
}